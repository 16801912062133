<template>
    <div class="text-center" v-if="!is_data_ready">
       <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>

    </div>
    <div class="tab-pane fade show active text-center" v-else-if="innings.length == 0 && is_data_ready">
        <p>{{ $t("No commentary data found") }}.</p>
    </div>
    <div v-else>
        <div class="innings_tabs cr-submain-tab">
            <ul>
                <li v-for="(inning,index) in innings" :key="inning.order">
                    <a :class="activeInningIndex == index ? 'selected':''" style="cursor: pointer" @click=" activeInningIndex = index; getCommentary(inning, true)" href="javascript:;">
                        <span>{{ inning.caption }}
                        </span>
                        <sup><i v-show="loading && activeInningIndex == index" class="fa fa-spinner fa-pulse fa-1x" aria-hidden="true"></i></sup>
                    </a>
                </li>
            </ul>
            <div class="clearfix"></div>
        </div>
        <div class="cr-commentary_tab" v-if="matchCommentary">
            <div class="commentary_tab_per_ball" v-for="commentary in matchCommentary" :key="commentary.id"><span class="commentary-over"> {{commentary.over}} </span><span
                    class="commentary-d-score"> {{commentary.d_score}} </span>
                <div class="commentary-descrip">
                    <h2 v-if="commentary.type_id != 91 && commentary.d_score != 'W' && commentary.d_score != '4' && commentary.d_score != '6'">{{commentary.player.name}} to {{commentary.batsman.name}}</h2>
                    <h2 v-if="commentary.d_score == 'W'"> <b class="clc_name_wout">{{commentary.player.name}} to {{commentary.batsman.name}}</b></h2>
                    <h2 v-if="commentary.d_score == '4'"> <b class="clc_name_four">{{commentary.player.name}} to {{commentary.batsman.name}}</b></h2>
                    <h2 v-if="commentary.d_score == '6'"> <b class="clc_name_sixer">{{commentary.player.name}} to {{commentary.batsman.name}}</b></h2>
                    <p>{{commentary.text}} </p>
                </div>
            </div>
            <div class="commentary_tab_per_ball">
                <span v-if="hasCommentaries" @click="page++; getCommentary(innings[activeInningIndex])" class="cr-load-more" >Load More <i v-show="loading" class="fa fa-spinner fa-pulse fa-1x" aria-hidden="true"></i></span>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "commentary",
        data() {
            return {
                matchCommentary: null,
                activeInningIndex:0,    //active tab inning
                matchCurrentInning:0,
                innings:'',
                loading: false,
                hasCommentaries: false,
                page: 1,
                inningsInterval: null

            }
        },
        mounted() {
            this.main();
            var _this = this;
            this.$root.$on('CricMatchUpdated', data => {
                if (data.match_id == _this.$route.params.id && _this.activeInningIndex == _this.matchCurrentInning) {
                    _this.getCommentary(_this.innings[_this.innings.length - 1], true)
                }
            });
            this.inningsInterval = setInterval(() => {
                this.innings = this.$store.getters.getMatchDetailResponse.innings;
            }, 60000);
        },
        beforeDestroy() {
            this.$root.$off('CricMatchUpdated')
            clearInterval(this.inningsInterval);
        },
        serverPrefetch() {
            return this.main()
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_commentary_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_commentary_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_commentary_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        methods: {
            getCommentary(inning, tabClick = false) {
                if (this.matchCommentary == null) {
                    this.is_data_ready = false;
                }
                if (tabClick) {
                    // this.is_data_ready = false;
                    this.page = 1;
                    this.hasCommentaries = false;
                    this.matchCommentary = null;
                }
                this.loading = true;
                const page = this.page;
                return this.$axios
                    .get(this.$MATCH_COMMENTARY_API.replace(':match', this.$route.params.slug), {
                        params: {
                            inning: inning.order,
                            page, page
                        }
                    })
                    .then(response => {
                        if (response.data) {
                            if (tabClick) {
                                this.matchCommentary = null;
                            }
                            if (this.matchCommentary == null) {
                                this.matchCommentary = response.data.result.commentaries;
                            } else {
                                Array.prototype.push.apply(this.matchCommentary, response.data.result.commentaries);
                            }
                            if (response.data.result.commentaries.length < 30) {
                                this.hasCommentaries = false;
                            } else if (response.data.result.commentaries.length > 0) {
                                this.hasCommentaries = true;
                            }
                        }
                        this.is_data_ready = true;
                        this.loading = false;
                    })
            },
            main() {
                this.innings = this.$store.getters.getMatchDetailResponse.innings;

                if(this.innings[0] && this.innings[0].order){
                    this.matchCurrentInning = this.activeInningIndex = this.innings.length - 1
                    return this.getCommentary(this.innings[this.innings.length - 1])
                }else {
                    this.is_data_ready = true;
                }
            }
        },

    }
</script>

<style scoped>

</style>
